import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Juliano = () => {
  return (
    <Layout>
      <Head title="juliano_so_cute_lowkey" />
      <h3>Juliano Hodges</h3>
      {/* <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Berkeley, CA
      </h4> */}
      <p>
        <OutboundLink href="https://www.tiktok.com/@juliano_so_cute_lowkey">
          TikTok
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/julianohodges/">
          Instagram
        </OutboundLink>
      </p>

      <div className="iframe_container">
        <iframe
          src="https://www.tiktok.com/embed/7337074729007844650"
          className="iframe"
          allowfullscreen
          scrolling="no"
          allow="encrypted-media;"
          title="Juliano 0"
        ></iframe>
      </div>

      <div className="iframe_container">
        <iframe
          src="https://www.tiktok.com/embed/7127433614567935275"
          className="iframe"
          allowfullscreen
          scrolling="no"
          allow="encrypted-media;"
          title="Juliano 1"
        ></iframe>
      </div>
      <div className="iframe_container">
        <iframe
          src="https://www.tiktok.com/embed/7133728613349870891"
          className="iframe"
          allowfullscreen
          scrolling="no"
          allow="encrypted-media;"
          title="Juliano 2"
        ></iframe>
      </div>
      <div className="iframe_container">
        <iframe
          src="https://www.tiktok.com/embed/7130739119705099566"
          className="iframe"
          allowfullscreen
          scrolling="no"
          allow="encrypted-media;"
          title="Juliano 3"
        ></iframe>
      </div>
      <div className="iframe_container">
        <iframe
          src="https://www.tiktok.com/embed/7129671968843910443"
          className="iframe"
          allowfullscreen
          scrolling="no"
          allow="encrypted-media;"
          title="Juliano 4"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Juliano;
